import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { generateCatalogImageAlt, generateCatalogImageTitle } from "@features/seo";
import { ImageList } from "@shared/lib/interfaces/car.interface";
import PlaceholderPic from "@shared/ui/Icon/ui/PlaceholderPic";
import { HoverGalleryProps, ImagesStatuses } from "./types";
import { DEBOUNCE_DELAY, MAX_PAGES, MIN_PICS_FOR_INDICATOR } from "./constants";
import classes from "./HoverGallery.module.scss";

const HoverGallery = ({
    style,
    pics,
    offer,
    isPriority = false,
    externalPage = 1,
    className
}: HoverGalleryProps) => {
    const component = useRef<HTMLDivElement | null>(null);
    const picsQnt = pics.length;
    const remainingPics = picsQnt > MAX_PAGES ? picsQnt - MAX_PAGES : 0;
    const [pagesQnt, setPagesQnt] = useState(0);
    const [page, setPage] = useState(externalPage);
    const [isPaginatorShown, setIsPaginatorShown] = useState(false);
    const altImage = generateCatalogImageAlt(offer);
    const titleImage = generateCatalogImageTitle(offer);

    const [imagesLoadStatuses, setImagesLoadStatuses] = useState<ImagesStatuses>(
        Array.from({ length: MAX_PAGES }, () => false)
    );

    const setImageStatus = (index: number, status: boolean) => {
        setImagesLoadStatuses((prev) => {
            const newStatuses = [...prev];
            newStatuses[index] = status;
            return newStatuses;
        });
    };

    const noPhotoLoaded = imagesLoadStatuses.every((el) => el === false);

    const mouseMove = debounce((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (component.current) {
            const componentElement = component.current;
            const componentBounding = componentElement.getBoundingClientRect();
            const componentWidth = componentElement.offsetWidth;
            const componentX = componentBounding.x;
            const mouseX = event.clientX - componentX;
            const partWidth = componentWidth / pagesQnt;
            let pageNumber = Math.floor(mouseX / partWidth) + 1;
            if (pageNumber > MAX_PAGES) {
                pageNumber = MAX_PAGES;
            }
            if (pageNumber < 1) {
                pageNumber = 1;
            }

            setPage(pageNumber);
        }
    }, DEBOUNCE_DELAY);

    const extractLinksFromPics = (pics: ImageList[]) => {
        const arrLen = pics.length;
        const defaultLinks = Array(arrLen).fill(
            pics[pics.length - 1]?.small || pics[pics.length - 1]?.big || null
        );
        return pics
            .map((el) => el.small || el.big)
            .concat(defaultLinks)
            .slice(0, arrLen);
    };
    const picsLinks = extractLinksFromPics(pics);

    const showIndicator = isPaginatorShown && !noPhotoLoaded && picsQnt >= MIN_PICS_FOR_INDICATOR;

    useEffect(() => {
        const qnt = picsQnt >= MAX_PAGES ? MAX_PAGES : picsQnt < 1 ? 0 : picsQnt;
        setPagesQnt(qnt);
    }, [pics]);

    useEffect(() => {
        if (externalPage !== undefined && externalPage !== page) {
            setPage(externalPage);
        }
    }, [externalPage]);

    return (
        <div
            onMouseMove={mouseMove}
            onMouseEnter={() => {
                setIsPaginatorShown(true);
            }}
            onMouseLeave={() => {
                setIsPaginatorShown(false);
                setTimeout(() => {
                    setPage(1);
                }, 10);
            }}
            className={classNames(classes.HoverGallery, className)}
            style={style}
        >
            <div ref={component} className={classes.HoverGallery__wrapper}>
                {picsLinks.map((link, index) => {
                    const isPreloadImage = isPriority && index === 0;
                    const isImageLoaded = imagesLoadStatuses[index];
                    const isShowOverlayMore =
                        index === MAX_PAGES - 1 && remainingPics > 0 && page === MAX_PAGES;

                    return (
                        <React.Fragment key={index}>
                            {!isImageLoaded && (
                                <div
                                    className={classNames("custom-loader", {
                                        [classes["HoverGallery__image--hidden"]]: page !== index + 1
                                    })}
                                >
                                    loading
                                </div>
                            )}
                            <Image
                                alt={altImage}
                                title={titleImage}
                                fill={true}
                                sizes="(max-width: 460px) 100vw, (max-width: 600px) 50vw, (max-width: 930px) 33.33vw, 25vw"
                                priority={isPreloadImage}
                                quality={80}
                                onError={() => {
                                    setImageStatus(index, false);
                                }}
                                onLoad={() => {
                                    setImageStatus(index, true);
                                }}
                                className={classNames(classes["HoverGallery__image"], {
                                    [classes["HoverGallery__image--hidden"]]: page !== index + 1
                                })}
                                src={picsLinks[index] || ""}
                            />
                            {isShowOverlayMore && (
                                <>
                                    <div className={classes.HoverGallery__overlay} />
                                    <div className={classes.HoverGallery__more}>
                                        Ещё
                                        <br />
                                        {remainingPics} фото
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    );
                })}

                {(noPhotoLoaded || pics.length === 0) && <PlaceholderPic width={"100%"} />}
            </div>
            {showIndicator && (
                <div className={classes.Indicator}>
                    {Array.from({ length: pagesQnt }).map((_, index) => (
                        <div
                            key={index}
                            className={classNames(classes.Indicator__bar, {
                                [classes["Indicator__bar--active"]]: page === index + 1
                            })}
                        ></div>
                    ))}
                </div>
            )}
        </div>
    );
};
export default HoverGallery;
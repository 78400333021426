import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import ImageGallery from "react-image-gallery";
import Image from "next/image";
import GalleryPrevIcon from "../../../components/Svg/GalleryPrevIcon";
import GalleryNextIcon from "../../../components/Svg/GalleryNextIcon";
import { useWindowSize } from "@src/hooks/useWindowsSize";
import PlaceholderPic from "@shared/ui/Icon/ui/PlaceholderPic";
import { generateCarImageAlt, generateCarImageTitle } from "@features/seo";
import { GalleryProps } from "./types";
import classes from "./Gallery.module.scss";
import GalleryImage from "./gallery-image";

const Lightbox = dynamic(() => import("react-spring-lightbox"), {
    ssr: false
});

const PREV_QNT = 5;

const Gallery = ({ className, offer }: GalleryProps) => {
    const [prevQnt, setPrevQnt] = React.useState(PREV_QNT);
    const size = useWindowSize();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const images = offer.pics.map((image) => {
        return {
            original: image.bigUrl ? image.bigUrl : "https://dummyimage.com/640x360/fff/aaa",
            originalAlt: generateCarImageAlt(offer),
            originalTitle: generateCarImageTitle(offer)
        };
    });

    useEffect(() => {
        if (size.width) {
            setPrevQnt(PREV_QNT);
            if (size.width < 768) {
                setPrevQnt(PREV_QNT - 1);
            }
            if (size.width < 610) {
                setPrevQnt(PREV_QNT - 2);
            }
            if (size.width < 520) {
                setPrevQnt(PREV_QNT - 2);
            }
            if (size.width < 450) {
                setPrevQnt(PREV_QNT - 3);
            }
        }
    }, [size.width]);

    const previews = images.slice(1, prevQnt + 1);

    const galleryRef = React.useRef(null);

    const onPrviewClick = (index: number) => {
        if (galleryRef.current && index !== prevQnt) {
            // @ts-ignore
            galleryRef.current.slideToIndex(index);
        } else if (index === prevQnt) {
            if (galleryRef.current) {
                // @ts-ignore
                const index = galleryRef.current.getCurrentIndex();
                setCurrentIndex(index);
            }

            setIsFullscreen(true);
        }
    };

    const [currentImageIndex, setCurrentIndex] = useState(0);

    const gotoPrevious = () => currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

    const gotoNext = () =>
        currentImageIndex + 1 < images.length && setCurrentIndex(currentImageIndex + 1);

    return (
        <div className={classes.Gallery}>
            {images.length > 0 && (
                <Lightbox
                    isOpen={isFullscreen}
                    onPrev={gotoPrevious}
                    onNext={gotoNext}
                    images={images.map((image) => {
                        return {
                            src: image.original,
                            loading: "eager",
                            alt: "car image"
                        };
                    })}
                    currentIndex={currentImageIndex}
                    style={{
                        backdropFilter: "blur(5px) brightness(40%)",
                        // @ts-ignore
                        webKitBackdropFilter: "blur(5px) brightness(40%)"
                    }}
                    onClose={() => setIsFullscreen(false)}
                    renderHeader={() => (
                        <div className={classes.FullscreenImageIndex}>
                            <div className={classes.ImageIndex}>
                                {currentImageIndex + 1} / {images.length}
                            </div>
                            <div
                                onClick={() => setIsFullscreen(false)}
                                className={classes.CloseIconCont}
                            >
                                <div className={classes.CloseIcon}></div>
                            </div>
                        </div>
                    )}
                    renderPrevButton={() => (
                        <div className={classes.PrevBtn} onClick={gotoPrevious}>
                            <GalleryPrevIcon />
                        </div>
                    )}
                    renderNextButton={() => (
                        <div className={classes.NextBtn} onClick={gotoNext}>
                            <GalleryNextIcon />
                        </div>
                    )}
                />
            )}
            {images.length === 0 && (
                <div
                    className={`${classes.GalleryCont} ${className}`}
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <PlaceholderPic />
                </div>
            )}
            {images.length > 0 && (
                <>
                    <ImageGallery
                        onClick={() => {
                            // @ts-ignore
                            setCurrentIndex(galleryRef.current.getCurrentIndex());
                            setIsFullscreen(true);
                        }}
                        disableKeyDown={true}
                        ref={galleryRef}
                        additionalClass={`${classes.GalleryCont} ${className}`}
                        items={images}
                        infinite={true}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        slideDuration={200}
                        showPlayButton={false}
                        onSlide={(index) => {
                            setCurrentIndex(index);
                        }}
                        renderRightNav={(onClick) => {
                            return <GalleryNextIcon className={classes.Next} onClick={onClick} />;
                        }}
                        renderLeftNav={(onClick) => {
                            return <GalleryPrevIcon className={classes.Prev} onClick={onClick} />;
                        }}
                        lazyLoad={true}
                        renderItem={(item) => {
                            return (
                                <GalleryImage
                                    index={currentImageIndex}
                                    src={item.original}
                                    alt={item.originalAlt}
                                    title={item.originalTitle}
                                    loading="eager"
                                />
                            );
                        }}
                    />

                    <div className={classes.Thumbnails}>
                        {previews.map((image, index) => {
                            return (
                                <div
                                    onClick={() => onPrviewClick(index + 1)}
                                    key={index}
                                    className={classes.Thumbnails__item}
                                >
                                    {index === previews.length - 1 && (
                                        <div className={classes.Thumbnails__layer}>
                                            <label className={classes["Thumbnails__layer--label"]}>
                                                Еще
                                            </label>
                                        </div>
                                    )}
                                    <Image
                                        className={classes.Thumbnails__image}
                                        src={image.original}
                                        width={140}
                                        height={100}
                                        alt={image.originalAlt}
                                        title={image.originalTitle}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};
export default Gallery;
import React from "react";
import Script from "next/script";
import { useDispatch } from "react-redux";
import { mapLoaded } from "@app/store/layoutSlice";

export const YandexMapLoader = () => {
    const dispatch = useDispatch();

    return (
        <Script
            src="https://api-maps.yandex.ru/2.1/?apikey=8cec1c13-67cf-4731-b7d4-746a7ea1bb71&lang=ru_RU"
            strategy="afterInteractive"
            type="text/javascript"
            onLoad={() => {
                dispatch(mapLoaded());
            }}
        />
    );
};
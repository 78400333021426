import React from "react";
import dynamic from "next/dynamic";
import Gallery from "../Gallery/Gallery";
import ProductDescription from "../ProductDescription/ProductDescription";
import ProductLocation from "../ProductLocation/ProductLocation";
import ProductProps from "../ProductProps/ProductProps";
import { ProductCardProps } from "./types";
import classes from "./ProductCard.module.scss";

const DynamicCardChart = dynamic(() => import("../../charts/CardChart/CardChart"), {
    ssr: false
});

const ProductCard = ({ offer }: ProductCardProps) => {
    return (
        <div className={classes.ProductCard}>
            <div className={classes.Gallery}>
                <Gallery offer={offer} className="product-card-gallery" />
            </div>
            <div className={classes.Props}>
                <ProductProps offer={offer} />
            </div>
            <div className={classes.Description}>
                <ProductDescription offer={offer} />
            </div>
            <div className={classes.Location}>
                <ProductLocation offer={offer} />
            </div>

            <div className={classes.Chart}>
                <DynamicCardChart offer={offer} maxSlices={6} />
            </div>
        </div>
    );
};
export default ProductCard;